import React from "react"

import SEO from "../components/SEO/SEO"
import Container from "../components/Container/Container"
import Title from "../components/Title/Title"
import Button from "../components/Button/Button"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container>
      <div className="flex flex-col justify-center py-12 text-center">
        <Title singlePage>404: Not Found!</Title>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <div className="flex justify-center mt-10">
          <Button arrowRight linkTo="/">
            Back to Home
          </Button>
        </div>
      </div>
    </Container>
  </>
)

export default NotFoundPage
